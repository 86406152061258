import axios from 'axios';
import config from './config.js';
import EventEmitter from 'events';

class Api {
    constructor() {
        this.baseUrl = config.apiProtocol + '://' + config.apiUrl + '/customer/';
        this.token = null;
        this.events = new EventEmitter();
        
        this.axiosConfig = {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        };

        this.getFileUrl = (key, name) => {
            return config.apiProtocol + '://' + config.apiUrl + '/files/' + key + '/' + name;
        }

        this.products = {
            search: (data) => { return this.callCommonEndpoint('products/search', 'post', data) },
            getPrestaImageUrl: (prestaProdId, imageId) => { return `${this.baseUrl}presta/product-image/${prestaProdId}/${imageId}?order=${this.token}` }
        }

        this.designer = {
            getProjects: () => { return this.callCommonEndpoint('designer/getProjectsByOrder', 'get') },
            addProject: (cartPosId, projectKey, variants) => { return this.callCommonEndpoint('designer/addExistingProject', 'post', { orderPosition: cartPosId, projectKey, variants }) },
        }

        this.order = {
            getProjectReadyState: () => this.callCommonEndpoint('order/project-ready-state', 'get'),
            getOrderStates:       () => this.callCommonEndpoint('order/available-states', 'get'),
            addFile:              (data) => this.callCommonEndpoint('order/file', 'put', data),
            get: () => { return this.callCommonEndpoint('order', 'get') },
            update: (data) => { return this.callCommonEndpoint('order', 'put', data) },
            place: (data) => { return this.callCommonEndpoint('order/place', 'post', data) },
            enableExpress: () => { return this.callCommonEndpoint('order/enableExpress', 'post', {}) },
            createNewCart: () => { return this.callCommonEndpoint('cart', 'post') },
            addProduct: (productId, source, data, qty) => { return this.callCommonEndpoint('order/addProduct', 'post', { productId, source, data, qty }) },

            getLogs: () => { return this.callCommonEndpoint('order/history') },

            getContent: () => { return this.callCommonEndpoint('order/contents', 'get') },
            
            updateVariant:(cartPosId, variantId, data) => { return this.callCommonEndpoint('order/contents/'+cartPosId+'/variant/'+variantId, 'put', data) },

            updateContent: (cartPosId, data) => { return this.callCommonEndpoint('order/contents/'+cartPosId, 'put', data) },
            removeContent: (cartPosId) => { return this.callCommonEndpoint('order/contents/'+cartPosId, 'delete') },

            setDelivery: (deliveryTypeId, data) => { return this.callCommonEndpoint('order/setDelivery', 'post', {deliveryTypeId, data}) },
            addCustomPos: (orderPositionId, data) => { return this.callCommonEndpoint('order/contents/'+orderPositionId+'/variant', 'post', data) },
            removeCartPos: (orderPositionId, variantId) => { return this.callCommonEndpoint('order/contents/'+orderPositionId+'/variant/'+variantId, 'delete') },
           
        }

        this.customer = {
            checkVoucher: (code) => { return this.callCommonEndpoint('voucher/check', 'post', { "code": code } ) },
            addNote: (data) => { return this.callCommonEndpoint('notes ', 'post', data) },
            getNotes: () => { return this.callCommonEndpoint('notes ') }
            
        }
        this.payments = {
            get: () => { return this.callCommonEndpoint('payments') },
            paynow:(data) => { return this.callCommonEndpoint('payments/pay/paynow', 'post', data) },
            zen:(data) => { return this.callCommonEndpoint('payments/pay/zen', 'post', data) },
            searchZenChannels:(amount) => { return this.callCommonEndpoint('payments/zen/channel/search', 'post', { amount }) },
            
        }
        this.features = {
            search: (data) => { return this.callCommonEndpoint('feature/search', 'post',  data) },
        }

        this.delivery = {
            search: (data) => { return this.callCommonEndpoint('delivery-types/search', 'post',  data) },
        }
        
    }

    uploadFile(file, progresCallback) {
        const promise = axios.post(this.baseUrl + 'files', file, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: this.axiosConfig.headers.Authorization
            },
            onUploadProgress: progresCallback
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                throw error.response;
            });
    
        return promise;
    }

    setToken(token) {
        this.token = token;
        this.axiosConfig.headers.Authorization = 'Bearer ' + token;
    }

    callCommonEndpoint(endpoint, method, data) {
        if (!method) method = 'get';
        return axios({
            method: method,
            url: this.baseUrl + endpoint,
            headers: this.axiosConfig.headers,
            data: JSON.stringify(data)
        }).then((response) => { return response.data; });
    }
}

let instance =  new Api();

export default instance;
