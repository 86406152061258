<template>
    <v-card>
        <v-toolbar dense color="primary" flat>
            <v-toolbar-title class="white--text">Dodaj własną pozycje do produktu</v-toolbar-title>
        </v-toolbar>
        <v-alert class="mx-4 mt-2 px-3 py-1" outlined type="primary" dense border="left" style=font-size:13px;>
            Dodaj własną pozycje dla wybranego produktu,<br/>
            gdy np. potrzebujesz zaproszeń w języku angielskim 
        </v-alert>
        <v-card-text class="pa-4">
            <v-row>
                <v-col cols="12" md="12" sm="12" >
                    <v-text-field
                        v-model="customPos.name"
                        label="Nazwa pozycji"
                        required
                        dense
                        hide-details
                        outlined
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="12" sm="12" >
                    <v-text-field
                        v-model="customPos.qty"
                        label="Ilość pozycji"
                        required
                        dense
                        type="number"
                        min="0"
                        :rules="numberRule"
                        outlined
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="12" sm="12"  class="mb-2">
                    <v-checkbox 
                        class="mt-0"
                        v-model="customPos.needGuestList"
                        label="Czy pozycja jest personalizowana"
                        hide-details
                    ></v-checkbox>
                    <span class="primary--text" style=font-size:13px;>Zanzacz jeśli chesz aby zaproszenia były personalizwowane (potrzeba będzie lista gości)</span>
                </v-col>
                <v-col cols="12" md="12" sm="12"  class="mb-2">
                    <v-checkbox 
                        class="mt-0"
                        v-model="customPos.needContent"
                        label="Czy pozycja potrzebuje treści"
                        hide-details
                    ></v-checkbox>
                    <span class="primary--text" style=font-size:13px;>Zanzacz jeśli chesz dodać własne treści do zaproszenia (potrzebne będą treści)</span>
                </v-col>
            </v-row>
            <v-divider/>
        </v-card-text>
            

            
        <v-card-actions class="pt-0">
            <v-spacer></v-spacer>
            <v-btn color="primary darken-1" text @click.native="agree">Zapisz</v-btn>
            <v-btn color="grey" text @click.native="cancel">Anuluj</v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
export default {
    props: {
    },
    data: () => ({
        customPos: {name:"", qty:"",needGuestList:false, needContent:true},
        
        numberRule:  [
            v => !isNaN(parseFloat(v)) && v >= 0 && v <= 999 || 'Podaj liczbę z zakresu 0 - 999',
        ],
    }),
    created() {
        // if(this.data != null) this.customPos = JSON.parse(JSON.stringify(this.data));
    },
    methods: {
        agree() {
            let qty = parseInt(this.customPos.qty);
            this.customPos.qty = qty;
            this.$emit('close',this.customPos);
            this.dialog = false;
        },
        cancel() {
            this.$emit('close',false)
            this.dialog = false;
        },
        
    }
}
</script>