<template>
    <v-dialog v-model="dialog" :max-width="options.width" @keydown.esc="cancel">
        <v-card>
            <v-toolbar dark :color="options.color" dense flat>
                <v-toolbar-title class="white--text">{{ title }}</v-toolbar-title>
            </v-toolbar>

            <v-card-text class="mt-2">
                Koszt jednej sztuki: {{ unitPriceFormatted }}
            </v-card-text>

            <v-card-text class="">
                <v-text-field
                    v-model="qty"
                    label="Ile sztuk chcesz zamówić"
                    required
                    outlined
                ></v-text-field>
            </v-card-text>

            <v-card-text>
                <div>Wartość pozycji</div>
                <p class="text-h4 mb-0 text--primary">{{ totalPrice }}</p>
            </v-card-text>

            <v-card-actions class="pt-0">
                <v-spacer></v-spacer>
                <v-btn color="primary darken-1" text @click.native="agree">Zapisz</v-btn>
                <v-btn color="grey" text @click.native="cancel">Anuluj</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
const moneyFormat = new Intl.NumberFormat('pl-PL', { style: 'currency', currency: 'PLN' });

export default {
    data: () => ({
        dialog: false,
        resolve: null,
        reject: null,
        unitPrice: 1,
        qty: 1,
        title: null,
        options: {
            color: 'primary',
            width: 320,
        }
    }),
    computed: {
        unitPriceFormatted() {
            return moneyFormat.format(this.unitPrice);
        },

        totalPrice() {
            return moneyFormat.format(this.qty * this.unitPrice);
        }
    },
    methods: {
        open(title, initQty, unitPrice, options) {
            this.dialog = true;
            this.unitPrice = unitPrice;
            this.qty = initQty;
            this.title = title;
            this.options = Object.assign(this.options, options)
            return new Promise((resolve, reject) => {
                this.resolve = resolve
                this.reject = reject
            })
        },
        agree() {
            let value = parseFloat(this.qty.replace(',', '.'));
            this.resolve(value);
            this.dialog = false;
        },
        cancel() {
            this.resolve(false)
            this.dialog = false
        }
    }
}
</script>