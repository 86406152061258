<template>
    <v-card>
        <v-toolbar dense color="primary" flat>
            <v-toolbar-title class="white--text">Zmiana wariantu produktu</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="pt-4">
            Użyj tego okna, żeby zmienić wariant swojego produktu
        </v-card-text>

        <v-card-text v-if="basePrice">
            <div>Bazowa cena produktu</div>
            <p class="text-h5 mb-0 text--primary">{{ basePrice | money }}/szt</p>
        </v-card-text>

        <v-card-text v-for="selector in selectors" :key="selector.featureId">
            <v-select :items="selector.options" item-text="label" item-value="name" v-model="edited[selector.featureId]" :label="selector.title" outlined />
        </v-card-text>

        <v-card-text class="caption">
            Zmiana wariantu może wiązać się ze zmianą kosztu całego zamówienia.
        </v-card-text>
        
        <v-card-actions class="pt-0">
            <v-spacer></v-spacer>
            <v-btn color="primary darken-1" text @click.native="agree">Zmień</v-btn>
            <v-btn color="grey" text @click.native="cancel">Anuluj</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
    props: {
        details: Object,
        features: Array,
        basePrice: Number,
    },

    data() {
        return {
            loading: false,
            featuresStore: [],
            edited: {}
        }
    },

    created() {
        if (this.features) this.featuresStore = this.features;
        this.edited = JSON.parse(JSON.stringify(this.details));
    },

    computed: {
        selectors: function () {
            let s = [];

            for (let featureId in this.edited) {
                let feature = this.featuresStore.find(f => f._id == featureId);
                s.push({
                    featureId,
                    title: feature.name,
                    options: feature.options.map(op => Object.assign({ label: op.name + " +" + this.$options.filters.money(op.priceAffect) }, op)),
                    feature,
                    value: this.edited[featureId]
                });
            }

            return s;
        }
    },

    methods: {
        agree() {
            this.$emit('close', this.edited);
        },

        cancel() {
            this.$emit('close', false);
        }
    },
}
</script>